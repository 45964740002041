import { REACTION_TYPES } from "./StreamHelpers";
import { logClickPushNotification } from "../models/Analytics";

const parseEnrichedObjects = (stringObj) => {
  if (!stringObj || typeof stringObj !== 'string') return stringObj;
  return JSON.parse(stringObj);
};

// dataObj should be something like props or state for most stream related views
export const assignAnalyticFields = (dataObj, debug = false) => {
  const combinedData = {...dataObj?.wholePostObj, ...dataObj?.data, ...dataObj};  // wholePostObj (post view)/data (cards) is the whole post passed through that may contain event, challenge, or group info. This is used for non-pushes.
  let {
      eventID,
      event,
      group,
      groupRecordType,
      groupID,
      group_id,
      challengeId,
      challenge_id,
      challenge,
      feed_origin_name,
      feed_origin_type,
      event_record_type,
      activity_sub_type,
      postID,
      comment,
      parentID,
      reactionType,
      reply_id,
      comment_id,
      post_id,
      click_text,
      event_id,
      kind,
      verb,
    } = combinedData;
    let analyticsObj = {};
    event = parseEnrichedObjects(event);
    if (event?.group && !group) {
      group = event.group;
    }
    if (event?.challenge && !challenge) {
      challenge = event.challenge;
    }
    if (event?.id) {
      analyticsObj.activity_sub_type = event.category || event.activity_sub_type;
      analyticsObj.event_record_type = event.is_virtual ? 'virtual' : 'event';
      analyticsObj.event_id = `${event.id}`;
      // these are from enriched events sent from pushes
      if (event?.challenge_id) analyticsObj.challenge_id = `${event.challenge_id}`;
      if (event?.chapter_id) analyticsObj.group_id = `${event.chapter_id}`;
      if (event?.chapter_type) analyticsObj.group_record_type = event.chapter_type;
      // these are from enriched events sent from in-app notifications
      if (event?.challenge?.id) analyticsObj.challenge_id = `${event.challenge.id}`;
      if (event?.group?.id) {
        analyticsObj.group_id = `${event.group.id}`;
        analyticsObj.group_record_type = event.group.type;
      }
    } else {
      if (event_id) {
        analyticsObj.event_id = `${event_id}`;
      }
      else if (eventID) {
        analyticsObj.event_id = `${eventID}`;
      }
      if (event_record_type) {
        analyticsObj.event_record_type = event_record_type;
      }
      if (activity_sub_type) {
        analyticsObj.activity_sub_type = activity_sub_type;
      }
    }
    group = parseEnrichedObjects(group);
    if (group?.id) {
      analyticsObj.group_id = `${group.id}`;
      analyticsObj.group_record_type = group.type;
    } else {
      if (groupID) {
        analyticsObj.group_id = `${groupID}`;
      }
      else if (group_id) {
        analyticsObj.group_id = `${group_id}`;
      }
      if (groupRecordType) {
        analyticsObj.group_record_type = groupRecordType;
      }
    }
    challenge = parseEnrichedObjects(challenge);
    if (challenge?.id) {
      analyticsObj.challenge_id = `${challenge.id}`;
    }
    else if (challengeId) {
      analyticsObj.challenge_id = `${challengeId}`;
    } else if (challenge_id) {
      analyticsObj.challenge_id = `${challenge_id}`;
    }
    // when viewing posts on the challenge feed, groups are nested in it
    if (challenge?.group) {
      analyticsObj.group_id = `${challenge?.group?.id}`;
      analyticsObj.group_record_type = `${challenge?.group?.type}`;
    }
    if (feed_origin_name) {
      analyticsObj.feed_origin_name = feed_origin_name;
    }
    if (feed_origin_type) {
      analyticsObj.feed_origin_type = feed_origin_type;
    }
    if (reply_id) {
      analyticsObj.object_reply = `${reply_id}`;
    }
    if (comment_id) {
      analyticsObj.object_comment = `${comment_id}`;
    }
    if (reactionType === REACTION_TYPES.REPLY) {
      analyticsObj.object_reply = `${comment?.id}` || `${comment?.commentID}`;
      analyticsObj.object_comment = `${parentID}`;
    } else if (reactionType === REACTION_TYPES.COMMENT) {
      analyticsObj.object_comment = `${comment.id}`;
    }
    if (post_id) {
      analyticsObj.object_post = `${post_id}`;
    }
    else if (postID) {
      analyticsObj.object_post = `${postID}`;
    }
    if (click_text) {
      analyticsObj.click_text = click_text;
    }
    // TODO: Dynamic importing was causing issues/not working
    // ideally this function handles dynamic importing to take care of web and mobile in one spot
    // for now this will have to be set by the caller.
    // if implemented, section_name should be omitted when used below
    // analyticsObj.section_name = await getSectionName();
    return analyticsObj;
}

export const assignPushAnalyticFieldsAndLog = (dataObj) => {
  // move iOS data up
  if (dataObj?.data) dataObj = Object.assign(dataObj, dataObj?.data);
  const clickText = dataObj?.message || dataObj?.alert || dataObj?.alert?.body || '';
  dataObj.click_text = clickText;
  if (!dataObj.kind && dataObj.title) dataObj.kind = dataObj.title;
  const analyticsObj = assignAnalyticFields(dataObj);
  logClickPushNotification(analyticsObj);
}
