import { isReplyRelatedVerb, NOTIFICATION_MESSAGES, NOTIFICATION_VERBS, REACTION_TYPES } from "./StreamHelpers";

const renderAction = (data) => {
    if (data.verb === 'follow') return 'started following you';
    else if (data.verb === 'update' || data.verb === 'cancel')
      return `${data.verb === 'update' ? 'updated' : 'canceled'} the event`;
    else if (data.verb === 'like' || data.verb === 'comment')
      return `${data.verb === 'like' ? 'liked' : 'commented on'} your post`;
    else if (data.verb === 'tag') {
      let type;
      if (data?.reply_id) type = REACTION_TYPES.REPLY;
      else if (data?.comment_id) type = REACTION_TYPES.COMMENT;
      else type = 'post';
      let mentionText;
      if (data.event_id) mentionText = 'on an event';
      else if (data.group_id) mentionText = 'in a group';
      else mentionText = `in a ${type}`;
      return `mentioned you ${mentionText}`;
    } else if (data.verb === 'post') {
      return 'posted on your event';
    } else if (data.verb === 'like:comment') {
      return 'liked your comment';
    } else if (data.verb === NOTIFICATION_VERBS.reply_author_received_like) {
      return NOTIFICATION_MESSAGES.reply_author_received_like;
    } else if (data.verb === NOTIFICATION_VERBS.comment_author_reply_received_like) {
      return NOTIFICATION_MESSAGES.comment_author_reply_received_like;
    } else if (data.verb === NOTIFICATION_VERBS.comment_author_comment_received_like) {
      return NOTIFICATION_MESSAGES.comment_author_comment_received_like;
    } else if (data.verb === NOTIFICATION_VERBS.comment_author_comment_received_reply) {
      return NOTIFICATION_MESSAGES.comment_author_comment_received_reply;
    } else if (data.verb === NOTIFICATION_VERBS.post_author_child_comment_liked) {
      return NOTIFICATION_MESSAGES.post_author_child_comment_liked;
    } else if (data.verb === NOTIFICATION_VERBS.post_author_child_comment_replied) {
      return NOTIFICATION_MESSAGES.post_author_child_comment_replied;
    } else if (data.verb === NOTIFICATION_VERBS.post_author_child_reply_liked) {
      return NOTIFICATION_MESSAGES.post_author_child_reply_liked;
    } else if (data.verb === NOTIFICATION_VERBS.reply) {
      return NOTIFICATION_MESSAGES.reply;
    } else if (data.verb === NOTIFICATION_VERBS.post_group) {
      return `${NOTIFICATION_MESSAGES.post_group}: ${data.group_name}`;
    } else if (data.verb === NOTIFICATION_VERBS.joined_group) {
      return `${NOTIFICATION_MESSAGES.joined_group}: ${data.group_name}`;
    } else if (data.verb === NOTIFICATION_VERBS.post_event) {
      return `has posted on the event`;
    }
    else return null; // ensure nothing is displayed if it is not a set type
};

const renderActorName = (data) => {
    const verb = data.verb;
    const actorUserName = data.actor_user?.first_name
      ? `${data.actor_user.first_name} ${data.actor_user.last_name}`
      : 'Deleted Member';
    const userName = data.user?.first_name
      ? `${data.user.first_name} ${data.user.last_name}`
      : 'Deleted Member';
    if (
      verb === 'update' ||
      verb === 'cancel' ||
      verb === 'post' ||
      verb === 'like' ||
      verb === 'comment' ||
      verb === 'like:comment' ||
      verb === NOTIFICATION_VERBS.post_event ||
      isReplyRelatedVerb(data.verb) ||
      verb === NOTIFICATION_VERBS.post_group ||
      verb === NOTIFICATION_VERBS.joined_group
    )
      return `${userName}`;
    else if (data.verb === 'tag' || data.verb === 'follow')
      return `${actorUserName}`;
};

export {
    renderAction,
    renderActorName
}
