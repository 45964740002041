import { rwbApi } from "../apis/api";
import { EXECUTION_STATUS, logDeletePost } from "../models/Analytics";

export const deletePost = (streamID, eventID, challengeID, groupID, analyticsObj) => {
    analyticsObj.click_text = 'delete post';
    if (eventID) {
        return rwbApi
            .deleteEventPost(eventID, streamID)
            .then(() => {
                analyticsObj.execution_status = EXECUTION_STATUS.success;
            })
            .catch((err) => {
                analyticsObj.execution_status = EXECUTION_STATUS.failure;
                console.warn(err);
            })
            .finally(() => {
                logDeletePost(analyticsObj);
            });
    }
    else if (challengeID) {
        return rwbApi
            .deleteChallengePost(challengeID, streamID)
            .then(() => {
                analyticsObj.execution_status = EXECUTION_STATUS.success;
            })
            .catch((err) => {
                analyticsObj.execution_status = EXECUTION_STATUS.failure;
                console.warn(err);
            })
            .finally(() => {
                logDeletePost(analyticsObj);
            });
    } else if (groupID) {
        return rwbApi
            .deleteGroupPost(groupID, streamID)
            .then(() => {
                analyticsObj.execution_status = EXECUTION_STATUS.success;
            })
            .catch((err) => {
                analyticsObj.execution_status = EXECUTION_STATUS.failure;
                console.warn(err);
            })
            .finally(() => {
                logDeletePost(analyticsObj);
            });
    }
    else {
        return rwbApi
            .deleteUserPost(streamID)
            .then(() => {
                analyticsObj.execution_status = EXECUTION_STATUS.success;
            })
            .catch((err) => {
                analyticsObj.execution_status = EXECUTION_STATUS.failure;
                console.warn(err);
            })
            .finally(() => {
                logDeletePost(analyticsObj);
            });
    }

};

// TODO: implement. This may be reversed as the aggregated feed card has some functionality that normal feed card may not
// if used, posts show up with images, posts can be liked, and comments can be made
export const deaggregateData = (data) => {
    if (!data.activities[0]) return data;
    return data.activities[0];
    
}

export const hasMediaChanged = (prevMedia, newMedia) => {
    if (prevMedia?.length !== newMedia?.length) {
        return true;
    }

    return prevMedia?.some((mediaItem, index) => 
        mediaItem !== newMedia[index]);
  }

export const REACTION_TYPES = {
    LIKE: 'like',
    COMMENT: 'comment',
    REPLY: 'reply'
}

export const NOTIFICATION_VERBS = {
    reply_author_received_like: "like:reply:reply_author", // to the reply author on like reaction
    comment_author_reply_received_like: "like:reply:comment_author", // to the original comment author on like of a reply
    comment_author_comment_received_like: "like:comment:comment_author", // to the comment author on direct reaction
    comment_author_comment_received_reply: "reply:comment:comment_author", // to the comment author on direct reaction
    // All post_author ones just care that activity happened on their post, even if all they did was make the post
    post_author_child_reply_liked: "like:reply:post_author", // to the original post/activity author on like of reply
    post_author_child_comment_liked: "like:comment:post_author", // to the post/activity author on comment reaction
    post_author_child_comment_replied: "reply:comment:post_author", // to the post/activity author on comment reaction
    reply: "reply:reply", // to the reply author on reply to reply
    tag: "tag", // to tagged users in reply or comment
    joined_group: "joined:group", // for group admins when a member joins their group
    post_group: "post:group",
    cancel: 'cancel',
    post: 'post',
    update: 'update',
    post_event: 'post:event',
}

export const NOTIFICATION_MESSAGES = {
    reply_author_received_like: 'liked your reply',
    comment_author_reply_received_like: 'liked a reply in your comment thread',
    comment_author_comment_received_like: 'liked your comment',
    comment_author_comment_received_reply: 'replied to your comment',
    post_author_child_reply_liked: 'liked a reply on your post',
    post_author_child_comment_liked: 'liked a comment on your post',
    post_author_child_comment_replied: 'replied to a comment on your post',
    reply: 'replied to your reply',
    tag: 'mentioned you in a comment/reply',
    joined_group: 'has joined your group',
    post_group: 'has posted in your group',
}

export const isReplyRelatedVerb = (verb) => {
    return (
        verb.includes('reply_author') ||
        verb.includes('comment_author') ||
        verb.includes('post_author') || 
        verb === NOTIFICATION_VERBS.reply
      )
}

export const canShowEnrichedEventName = (verb) => {
    return verb === NOTIFICATION_VERBS.cancel || verb === NOTIFICATION_VERBS.update || verb === NOTIFICATION_VERBS.post || verb === NOTIFICATION_VERBS.post_event;
}
