import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {rwbApi} from '../../../shared/apis/api';
import PostOptionIcon from '../components/svgs/PostOptionIcon';
import {userProfile} from '../../../shared/models/UserProfile';
import styles from './ReportAndDeleteOverlay.module.css';
import {
  DELETE_GROUP_USER_ERROR,
  DELETE_GROUP_USER_SUCCESS,
  PIN_POST_ERROR,
  PIN_POST_SUCCESS,
  REMOVE_GROUP_POST_ERROR,
  REMOVE_GROUP_POST_SUCCESS,
  REPORT_ERROR,
  UNPIN_POST_ERROR,
  UNPIN_POST_SUCCESS,
} from '../../../shared/constants/ErrorMessages';
import CreatePost from './feed/CreatePost';
import CreateComment from './feed/CreateComment';
import {hoursMinutesSecondsFromMinutes} from '../../../shared/utils/ChallengeHelpers';
import {EL_MANAGEMENT_ACTION_LABELS, FEED_ACTION_LABELS, FEED_TYPE} from '../../../shared/constants/Labels';
import {matchPath} from 'react-router-dom';
import {
  EXECUTION_STATUS,
  logReport,
  webSectionName,
} from '../../../shared/models/Analytics';
import {capitalizeFirstLetter} from '../../../shared/utils/Helpers';
import {GroupManagement} from '../../../shared/utils/GroupManagement';
import ELManagementModal from './ELManagementModal';
import EagleLeaderInfo from '../../../shared/models/EagleLeaderInfo';
import errorAlert from '../../../shared/utils/ErrorAlert';
import { REACTION_TYPES } from '../../../shared/utils/StreamHelpers';
import { assignAnalyticFields } from '../../../shared/utils/AnalyticsFieldAssigner';

const routeConfig = {
  Group: '/groups/:groupId',
  GroupPost: '/groups/:groupId/feed/:streamId/user/:userId',
  Challenge: '/challenges/:challengeId',
  ChallengePost: '/challenges/:challengeId/feed/:streamId/user/:userId'
};
export default class ReportAndDeleteOverlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      overlayVisible: false,
      createPostVisible: false,
      onboarded: false,
      actionSheetVisible: false,
      managementType: null,
    };
    this.eagleLeaderInfo = new EagleLeaderInfo(this.props?.user?.eagle_leader_info);
    this.currentUser = userProfile.getUserProfile();
    this.isEditable = this.eagleLeaderInfo.isEditable() && this.currentUser.id !== this.props?.user?.id && this.props.hasPermission;
  }
  
  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = (event) => {
    const domNode = ReactDOM.findDOMNode(this);

    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        overlayVisible: false,
      });
    }
  };

  // determines which type of report is being done
  report = () => {
    if (
      window.confirm(
        `Team RWB: Would you like to report this ${this.props.type}?`,
      )
    ) {
      const reporterIDPayload = JSON.stringify({
        reporter: this.currentUser.id,
      });
      if (this.props.type === 'post') {
        if (this.props.eventID) this.reportEventPost(reporterIDPayload);
        else if (this.props.groupID) this.reportGroupPost(reporterIDPayload);
        else if (this.props.posterID) this.reportUserPost(reporterIDPayload);
      } else if (this.props.type === 'comment') {
        this.reportReaction(this.props.posterID, reporterIDPayload);
        return;
      }
      else if (this.props.type === 'member'){
        this.reportUser(reporterIDPayload);
        return;
      }
      else if (this.props.type === REACTION_TYPES.REPLY) {
        const payload = JSON.stringify({
          reporter: this.currentUser.id,
          reply_id: this.props.comment.id,
        });
        this.reportReaction(this.props?.comment?.user?.id, payload);
        return;
      }
      else {
        throw new Error("Invalid Type, expecting the type 'post', 'comment', or 'reply'");
      }
    } else {
      this.setState({overlayVisible: false});
    }
  };

  delete = () => {
    this.props.deletePost();
    this.setState({overlayVisible: false});
  };

  edit = () => {
    this.setState({
      overlayVisible: false,
      createPostVisible: true,
    });
  };

  baseAnalyticsObj = () => {
    let analyticsObj = assignAnalyticFields(this.props);
    analyticsObj.section_name = webSectionName();
    analyticsObj.object_id = this.props.streamID;
    return analyticsObj;
  };

  reportUserPost = (reporterID) => {
    let analyticsObj = this.baseAnalyticsObj();
    analyticsObj.action_category = 'post';
    rwbApi
      .reportUserPost(this.props.posterID, this.props.streamID, reporterID)
      .then(() => {
        analyticsObj.execution_status = EXECUTION_STATUS.success;
        window.alert('Team RWB: Your report has been sent');
      })
      .catch((err) => {
        analyticsObj.execution_status = EXECUTION_STATUS.failure;
        window.alert(`Team RWB: ${REPORT_ERROR}`);
        console.warn(err);
      })
      .finally(() => {
        logReport(analyticsObj);
        this.setState({overlayVisible: false});
      });
  };

  reportUser = (reporterID) => {
    let analyticsObj = this.baseAnalyticsObj();
    analyticsObj.action_category = 'post';
    rwbApi
      .reportUser(this.props.posterID, reporterID)
      .then(() => {
        analyticsObj.execution_status = EXECUTION_STATUS.success;
        window.alert('Team RWB: Your report has been sent');
      })
      .catch((err) => {
        analyticsObj.execution_status = EXECUTION_STATUS.failure;
        window.alert(`Team RWB: ${REPORT_ERROR}`);
        console.warn(err);
      })
      .finally(() => {
        logReport(analyticsObj);
        this.setState({overlayVisible: false});
      });
  };

  reportEventPost = (reporterID) => {
    let analyticsObj = this.baseAnalyticsObj();
    analyticsObj.action_category = 'post';
    rwbApi
      .reportEventPost(this.props.eventID, this.props.streamID, reporterID)
      .then(() => {
        analyticsObj.execution_status = EXECUTION_STATUS.success;
        window.alert('Team RWB: Your report has been sent');
      })
      .catch((err) => {
        analyticsObj.execution_status = EXECUTION_STATUS.failure;
        window.alert(`Team RWB: ${REPORT_ERROR}`);
        console.warn(err);
      })
      .finally(() => {
        logReport(analyticsObj);
        this.setState({overlayVisible: false});
      });
  };

  // comment or reply
  reportReaction = (reportedUserID, reporterIDPayload) => {
    let analyticsObj = this.baseAnalyticsObj();
    analyticsObj.action_category = this.props.type;
    rwbApi
      .reportReaction(reportedUserID, this.props.commentID, reporterIDPayload)
      .then(() => {
        analyticsObj.execution_status = EXECUTION_STATUS.success;
        window.alert('Team RWB: Your report has been sent');
      })
      .catch((err) => {
        analyticsObj.execution_status = EXECUTION_STATUS.failure;
        window.alert(`Team RWB: ${REPORT_ERROR}`);
        console.warn(err);
      })
      .finally(() => {
        logReport(analyticsObj);
        this.setState({overlayVisible: false});
      });
  };

  reportGroupPost = (reporterID) => {
    let analyticsObj = this.baseAnalyticsObj();
    analyticsObj.action_category = 'post';
    const groupID = this.props.groupID?.id || this.props.groupID;
    rwbApi
      .reportGroupPost(groupID, this.props.streamID, reporterID)
      .then(() => {
        analyticsObj.execution_status = EXECUTION_STATUS.success;
        window.alert('Team RWB: Your report has been sent');
      })
      .catch((err) => {
        analyticsObj.execution_status = EXECUTION_STATUS.failure;
        window.alert(`Team RWB: ${REPORT_ERROR}`);
        console.warn(err);
      })
      .finally((result) => {
        logReport(analyticsObj);
        this.setState({overlayVisible: false});
      });
  };

  closeComment = (refresh, data = null) => {
    if (refresh) this.props.refreshReactions();
    else if (data && this.props.updateComment) this.props.updateComment(data);
    this.setState({createPostVisible: false});
  };

  pinPost() {
    let analyticsObj = this.baseAnalyticsObj();
    analyticsObj.profile_id = `${this.props.posterID}`;
    analyticsObj.has_image = this.props?.image !== null;
    if (this.props.groupID) this.pinGroupPost(analyticsObj);
    else if (this.props.challengeID) this.pinChallengePost(analyticsObj);
  }

  unpinPost() {
    let analyticsObj = this.baseAnalyticsObj();
    analyticsObj.profile_id = `${this.props.posterID}`;
    analyticsObj.has_image = this.props?.image !== null;
    if (this.props.groupID) this.unpinGroupPost(analyticsObj);
    else if (this.props.challengeID) this.unpinChallengePost(analyticsObj);
  }

  pinChallengePost() {
    rwbApi.pinChallengePost(this.props.challengeID, this.props.streamID).then((result) => {
      this.setState({overlayVisible: false}, () => {
        this.props.handlePinPost();
      })
    }).catch((err) => {
      errorAlert(err.message);
      this.setState({overlayVisible: false});
    }).finally(() => {
      // analytics
    });
  }

  unpinChallengePost() {
    rwbApi.unpinChallengePost(this.props.challengeID, this.props.streamID).then((result) => {
      this.setState({overlayVisible: false}, () => {
        this.props.handleUnpinPost();
      })
    }).catch((err) => {
      errorAlert(err.message);
      this.setState({overlayVisible: false});
    }).finally(() => {
      // analytics
    });
  }

  pinGroupPost(analyticsObj) {
    GroupManagement.pinGroupPost(
      this.props.streamID,
      this.props.groupID,
      analyticsObj,
    )
      .then(() => {
        this.setState({overlayVisible: false}, () => {
          // this.props.mergeNewPost();
          this.props.handlePinPost();
        });
      })
      .catch((err) => {
        window.alert(`Team RWB: ${err.message}`);
        this.setState({overlayVisible: false});
      });
  }

  unpinGroupPost(analyticsObj) {
    GroupManagement.removeGroupPinnedPost(
      this.props.streamID,
      this.props.groupID,
      analyticsObj,
    )
      .then(() => {
        this.setState({overlayVisible: false}, () => {
          this.props.handleUnpinPost();
          // this.props.mergeNewPost();
        });
      })
      .catch(() => {
        window.alert(`Team RWB: ${UNPIN_POST_ERROR}`);
        this.setState({overlayVisible: false});
      });
  }

  removePostAndReport() {
    const reporterID = JSON.stringify({
      reporter: this.currentUser.id,
    });
    let analyticsObj = this.baseAnalyticsObj();
    analyticsObj.profile_id = `${this.props.posterID}`;
    analyticsObj.has_image = this.props?.image !== null;
    GroupManagement.removeContentGroupAndReport(
      this.props.streamID,
      this.props.groupID,
      analyticsObj,
    )
      .then(() => {
        this.reportGroupPost(reporterID);
        this.setState({overlayVisible: false}, () => {
          window.alert(`Team RWB: ${REMOVE_GROUP_POST_SUCCESS}`);
          // this.props.mergeNewPost();
          this.props.handleRemoveContent();
        });
      })
      .catch((err) => {
        this.setState({overlayVisible: false}, () => {
          window.alert(`Team RWB: ${REMOVE_GROUP_POST_ERROR}`);
        });
      });
  }

  deleteUserAndReport() {
    const reporterID = JSON.stringify({
      reporter: this.currentUser.id,
    });
    let analyticsObj = this.baseAnalyticsObj();
    analyticsObj.profile_id = `${this.props.posterID}`;
    analyticsObj.has_image = this.props?.image !== null;
    GroupManagement.removeUserGroupAndReport(
      this.props.posterID,
      this.props.groupID,
      analyticsObj,
    )
      .then(() => {
        this.reportUserPost(reporterID);
        this.setState({overlayVisible: false}, () => {
          window.alert(`Team RWB: ${DELETE_GROUP_USER_SUCCESS}`);
          // this.props.mergeNewPost();
          this.props.handleRemoveUser();
        });
      })
      .catch((err) => {
        this.setState({overlayVisible: false}, () => {
          if ((err.message = 'Group membership does not exist.')) {
            window.alert('Team RWB: This user has already been removed.');
          } else {
            window.alert(`Team RWB: ${DELETE_GROUP_USER_ERROR}`);
          }
        });
      });
  }
  
  onBoardHandler = () => {
    this.setState({
      actionSheetVisible: true,
      managementType: 'Onboard Eagle Leader'
    });
  };

  offBoardHandler = () => {
    this.setState({
      actionSheetVisible: true,
      managementType: 'Offboard Eagle Leader'
    });
  };

  editELHandler = () =>{
    this.setState({
      actionSheetVisible: true,
      managementType: 'Eagle Leader'
    });
  }

  validControlRoute = (tab) => {
    const isMainView = matchPath(window.location.pathname, {
      path: routeConfig[tab],
      exact: true
    });
    const isPostView = matchPath(window.location.pathname, {
      path: routeConfig[`${tab}Post`],
      exact: true
    });
    if (isMainView || isPostView) return true;
    return false;
  }

  getOverlayOptions() {
    const canDelete = this.props.canDelete;
    const isPostPinned = this.props.isPinned;
    const postType = this.props.type;
    const isGroupFeedAdmin = this.props.isAdmin && this.validControlRoute('Group'); // pinning post, removing users, etc
    const isGroupFeedModerator = this.props.isModerator && this.validControlRoute('Group'); // pinning post and unpinning posts
    const isChallengeGroupAdmin = this.props.isAdmin && this.validControlRoute('Challenge');
    const isGroupList = this.props.isGroupList;

    const options = {
      editOrViewELInfo:{
        label:  this.isEditable ? EL_MANAGEMENT_ACTION_LABELS.VIEW_EDIT : EL_MANAGEMENT_ACTION_LABELS.VIEW,
        action: () => this.editELHandler(),
      },
      onBoard:{
        label: EL_MANAGEMENT_ACTION_LABELS.ONBOARD,
        action: () => this.onBoardHandler(),
      },
      offBoard:{
        label: EL_MANAGEMENT_ACTION_LABELS.OFFBOARD,
        action: () => this.offBoardHandler(),
      },
      pinPost: {
        label: FEED_ACTION_LABELS.PIN_POST,
        action: () => this.pinPost(),
      },
      unpinPost: {
        label: FEED_ACTION_LABELS.UNPIN_POST,
        action: () => this.unpinPost(),
      },
      delete: {
        label: FEED_ACTION_LABELS.DELETE_POST,
        action: () => this.delete(),
      },
      edit: {
        label: FEED_ACTION_LABELS.EDIT_POST,
        action: () => this.edit(),
      },
      block: {
        label: FEED_ACTION_LABELS.BLOCK_USER,
        action: () => this.props.blockPost(),
      },
      report: {
        label: isGroupList ? EL_MANAGEMENT_ACTION_LABELS.REPORT : FEED_ACTION_LABELS.REPORT_POST,
        action: () => this.report(),
      },
      removePostAndReport: {
        label: `${
          FEED_ACTION_LABELS.REMOVE_POST_AND_REPORT
        } ${capitalizeFirstLetter(postType)}`,
        action: () => this.removePostAndReport(),
      },
      deleteUserAndReport: {
        label: FEED_ACTION_LABELS.DELETE_USER_AND_REPORT,
        action: () => this.deleteUserAndReport(),
      }
    };

    const baseGroupModeratorPermissions = this.props.isGroupAdmin && isGroupList // base permissions for onboarding/offboarding/view/edit
    const isDifferentUser = this.currentUser.id !== this.props.user?.id
    const permissions = {
      pinPost: (isGroupFeedAdmin || isGroupFeedModerator || isChallengeGroupAdmin) && !isPostPinned,
      unpinPost: (isGroupFeedAdmin || isGroupFeedModerator || isChallengeGroupAdmin) && isPostPinned,
      delete: canDelete,
      edit: canDelete,
      block: !canDelete && postType === FEED_TYPE.POST && !isGroupFeedAdmin,
      report: !canDelete && !isGroupFeedAdmin && isDifferentUser,
      removePostAndReport:
        !canDelete && isGroupFeedAdmin && postType === FEED_TYPE.POST,
      deleteUserAndReport:
        !canDelete && isGroupFeedAdmin && postType === FEED_TYPE.POST,
      onBoard: baseGroupModeratorPermissions && this.eagleLeaderInfo.status === EagleLeaderInfo.STATUS.IS_NOT_EAGLE_LEADER && this.props.user?.public_profile && isDifferentUser,
      offBoard: baseGroupModeratorPermissions && this.eagleLeaderInfo.status === EagleLeaderInfo.STATUS.ACTIVE && this.isEditable,
      editOrViewELInfo: baseGroupModeratorPermissions && this.eagleLeaderInfo.eagleLeaderInfo,
    };

    return Object.keys(options).reduce((opts, option) => {
      if (permissions[option]) opts.push(options[option]);
      return opts;
    }, []);
  }

  render() {
    return (
      <div ref={this.wrapperRef} style={{position: 'relative'}}>
        {!this.props.eventStatusPost ? (
          <div
            className={styles.optionsContainer}
            onClick={(e) => {
              this.setState({overlayVisible: true});
              e.stopPropagation();
            }}>
            <PostOptionIcon height="28" width="28" />
          </div>
        ) : null}

        {this.state.overlayVisible ? (
          <div className={styles.container}>
            {this.getOverlayOptions().map((option, index) => (
              <button
                className={
                  option.label.toLowerCase().includes('delete')
                    ? styles.dangerous
                    : null
                }
                onClick={(e) => {
                  option.action();
                  e.stopPropagation();
                }}
                key={index}>
                {option.label}
              </button>
            ))}
          </div>
        ) : null}

        {this.state.actionSheetVisible ? (
          <div className={styles.editContainer} onClick={(e) => e.stopPropagation()}>
          <ELManagementModal
            closeModalHandler={() => {
              this.setState({actionSheetVisible: false})
            }}
            groupName={this.props.groupName}
            title={this.state.managementType}
            user={this.props.user}
            editableEL={this.isEditable}
            handleUpdatedELInfo={this.props?.handleUpdatedELInfo}
          />
          </div>
          )
          :
          null
        }

        {this.state.createPostVisible && (
          <div className={styles.editContainer}>
            {this.props.commentID ? (
              <CreateComment
                poster={this.props.poster}
                commenter={this.props.commenter}
                comment={this.props.comment}
                streamID={this.props.streamID}
                closeModalHandler={this.closeComment}
                verbEvent={this.props.verbEvent}
                time={this.props.time}
                text={this.props.text}
                tagged={this.props.tagged}
                history={this.props.history}
                postImage={this.props.image}
                postImages={this.props.images}
                posterText={this.props.posterText}
                parentTagged={this.props.parentTagged}
                posterTime={this.props.posterTime}
                commentID={this.props.commentID}
                title={this.props.title}
                verb={this.props.verb}
                eventID={this.props.eventID}
                groupID={this.props.groupID}
                challengeId={this.props.challengeID || this.props.challengeId}
                event_record_type={this.props.event_record_type}
                group_record_type={
                  this.props?.groupRecordType || this.props.group_record_type
                }
                activity_sub_type={this.props.activity_sub_type}
                feed_origin_name={this.props.feed_origin_name}
                feed_origin_type={this.props.feed_origin_type}
                graphData={this.props.graphData}
                postGraphData={this.props.postGraphData}
                reactionType={this.props.type}
                wholePostObj={this.props.wholePostObj}
                newReaction={false}
              />
            ) : (
              <CreatePost
                type={this.props.type}
                eventID={this.props.eventID}
                groupID={this.props.groupID}
                challengeID={this.props.challengeID}
                text={this.props.text}
                image={this.props.image}
                images={this.props.images}
                tagged={this.props.tagged}
                id={this.props.streamID}
                closeModalHandler={() =>
                  this.setState({createPostVisible: false})
                }
                mergeNewPost={this.props.mergeNewPost}
                eventName={this.props.workout?.event_name}
                chapterName={this.props.workout?.chapter_name}
                eventStartTime={this.props.workout?.event_start_time}
                miles={this.props.workout?.miles}
                steps={this.props.workout?.steps}
                hours={
                  hoursMinutesSecondsFromMinutes(this.props.workout?.minutes)
                    .hours
                }
                minutes={
                  hoursMinutesSecondsFromMinutes(this.props.workout?.minutes)
                    .minutes
                }
                seconds={
                  hoursMinutesSecondsFromMinutes(this.props.workout?.seconds)
                    .seconds
                }
                groupRecordType={this.props?.groupRecordType}
                challengeId={this.props.challengeID || this.props.challengeId}
                event_record_type={this.props.event_record_type}
                group_record_type={
                  this.props?.groupRecordType || this.props.group_record_type
                }
                activity_sub_type={this.props.activity_sub_type}
                graphData={this.props.graphData}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}
