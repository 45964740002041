import React, {Component} from 'react';
import styles from './CommentView.module.css';
import DefaultProfileImg from '../../../../shared/images/DefaultProfileImg.png';
import LikeIcon from '../svgs/LikeIcon';
import ReportAndDeleteOverlay from '../ReportAndDeleteOverlay';
import {howLongAgo, getHyperlinks, filterPostMedia} from '../../../../shared/utils/Helpers';
import {withRouter} from 'react-router-dom';
import FormattedPostText from './FormattedPostText';
import {userProfile} from '../../../../shared/models/UserProfile';
import {rwbApi} from '../../../../shared/apis/api';
import {
  EXECUTION_STATUS,
  logDeleteComment,
  logDeleteReply,
  logLikeComment,
  logLikeReply,
  logViewLikeList,
  webSectionName,
} from '../../../../shared/models/Analytics';
import SitePreviewCard from '../cards/SitePreviewCard';
import CreateComment from './CreateComment';
import { CreatedPostImageDisplay } from './PostImageDisplay.react';
import ReplyIcon from '../svgs/ReplyIcon';
import { REACTION_TYPES } from '../../../../shared/utils/StreamHelpers';
import { assignAnalyticFields } from '../../../../shared/utils/AnalyticsFieldAssigner';

class CommentView extends Component {
  constructor(props) {
    super(props);
    const {content, tagged, edited, own_like_id, reaction_counts, children_counts, media } = this.props.comment;
    this.reactionType = this.props.reactionType || REACTION_TYPES.COMMENT;
    this.state = {
      deleted: false,
      content,
      media,
      tagged,
      edited,
      liked: own_like_id,
      likeCount: reaction_counts?.like || children_counts?.like || 0,
      likeInProgress: false,
      replyModalVisible: false,
      replyData: {taggedUser: null},
      replies: this.props?.comment?.latest_children?.reply || [],
    };
  }

  componentDidUpdate(prevProps) {
    if (this.reactionType === REACTION_TYPES.COMMENT) {
      const prev = prevProps?.comment?.latest_children?.reply;
      const commentReplies = this.props?.comment?.latest_children?.reply;
      const lastPrevPropId = prev?.length > 0 ? prev[prev?.length - 1]?.id : null;
      const lastCommentRepliesId = commentReplies?.length > 0 ? commentReplies[commentReplies?.length - 1]?.id : null;
      if (lastPrevPropId !== lastCommentRepliesId || prev?.length !== commentReplies?.length) {
        this.setState(prevState => ({
          replies: [...commentReplies] || [],
        }));
      }
    }
  }

  // only the creator of a post can delete
  canDelete = () => {
    const commenterID = this.props.comment.user?.id.toString();
    const currentUserID = userProfile.getUserProfile().id.toString();
    return commenterID === currentUserID;
  };

  baseAnalyticsObj = () => {
    let analyticsObj = assignAnalyticFields(this.props);
    analyticsObj.section_name = webSectionName();
    return analyticsObj;
  };

  deleteComment = () => {
    if (
      window.confirm(
        `Delete ${this.reactionType}: Are you sure you want to delete your ${this.reactionType}?`,
      )
    ) {
      let analyticsObj = this.baseAnalyticsObj();
      const reactionKind = JSON.stringify({kind: this.reactionType});
      rwbApi
        .deleteReaction(
          this.props.posterID,
          this.props.postID,
          reactionKind,
          this.props.comment.id,
        )
        .then(() => {
          analyticsObj.execution_status = EXECUTION_STATUS.success;
          // decrement comment count
          if (this.reactionType === REACTION_TYPES.COMMENT) {
            this.props.handleCommentDeletion();
          }
          this.setState({deleted: true});
          if (this.reactionType === REACTION_TYPES.REPLY) {
            this.props.onDelete(this.props.comment.id);
          }
        })
        .catch(() => {
          analyticsObj.execution_status = EXECUTION_STATUS.failure;
        })
        .finally(() => {
          if (this.reactionType === REACTION_TYPES.COMMENT) {
            logDeleteComment(analyticsObj);
          } else if (this.reactionType === REACTION_TYPES.REPLY) {
            logDeleteReply(analyticsObj);
          }
        });
    }
  };

  updateComment = (data) => {
    this.setState({
      content: data.content,
      media: filterPostMedia(data.media, 'image'),
      tagged: data.tagged,
      edited: true,
    });
  };

handleReplyDeleted = (id) => {
  this.setState(prevState => ({
      replies: prevState.replies.map(reply =>
          reply.id === id ? { ...reply, deleted: true } : reply
      ),
  }));
};

  toggleLike = () => {
    if (this.state.likeInProgress) return;
    const currentUserID = userProfile.getUserProfile().id.toString();
    const likePayload = {
      kind: 'like',
      comment_id: this.props.parentID ? this.props.parentID : this.props.comment.id,
    };
    let analyticsObj = this.baseAnalyticsObj();
    if (this.reactionType === REACTION_TYPES.REPLY) {
      likePayload.reply_id = this.props.comment.id;
    }
    if (this.props.offset) likePayload.offset = this.props.offset;
    if (this.state.liked) {
      this.setState({
        liked: false,
        likeCount: this.state.likeCount - 1,
        likeInProgress: true,
      });
      rwbApi
        .deleteReaction(
          currentUserID,
          this.props.postID,
          JSON.stringify(likePayload),
        )
        .catch(() => {
          alert(`Error unliking the ${this.reactionType}.`);
          this.setState({liked: true, likeCount: this.state.likeCount + 1});
        })
        .finally(() => {
          // Are we logging unliking too, we were not before?
          this.setState({likeInProgress: false});
        });
    } else {
      this.setState({
        liked: true,
        likeCount: this.state.likeCount + 1,
        likeInProgress: true,
      });
      rwbApi
        .postReaction(
          currentUserID,
          this.props.postID,
          JSON.stringify(likePayload),
        )
        .then(() => {
          analyticsObj.execution_status = EXECUTION_STATUS.success;
        })
        .catch(() => {
          alert(`Error liking the ${this.reactionType}.`);
          analyticsObj.execution_status = EXECUTION_STATUS.failure;
          this.setState({liked: false, likeCount: this.state.likeCount - 1});
        })
        .finally(() => {
          if (this.reactionType === REACTION_TYPES.COMMENT) logLikeComment(analyticsObj);
          else if (this.reactionType === REACTION_TYPES.REPLY) logLikeReply(analyticsObj);
          this.setState({likeInProgress: false});
        });
    }
  };

  render() {
    const {history} = this.props;
    const {user, time, graphData, id} = this.props.comment;
    const {content, tagged, edited} = this.state;
    const links = getHyperlinks(content);
    return !this.state.deleted ? (
      <div className={styles.commentContainer} style={{borderBottom: this.reactionType === REACTION_TYPES.REPLY && 0, paddingLeft: this.reactionType === REACTION_TYPES.REPLY ? 0 : null}}>
        <div className={styles.userCommentContainer}>
          {this.reactionType === REACTION_TYPES.REPLY && <div style={{marginRight: 5}}>
              <ReplyIcon />
            </div>
          }
          <div
            className={`${styles.userImageContainer} ${this.reactionType === REACTION_TYPES.COMMENT ? styles.commentImageContainer : styles.replyImageContainer}`}
            onClick={() => history.push(`/profile/${user?.id}`)}>
            <img
              className={styles.profileImage}
              src={user?.profile_photo_url || DefaultProfileImg}
              alt="User Profile Image"
            />
          </div>
          <p>
            <span
              className={`namesAndObjects ${styles.name}`}
              onClick={() => history.push(`/profile/${user?.id}`)}>
              {`${user?.first_name} ${user?.last_name}`}&nbsp;
            </span>
            • {howLongAgo(time) || ''}
            {edited && <p className={'edited'}> Edited</p>}
          </p>
          <div className={styles.reportDeleteContainer}>
            <ReportAndDeleteOverlay
              updateComment={this.updateComment}
              canDelete={this.canDelete()}
              deletePost={this.deleteComment}
              streamID={this.props.postID}
              comment={this.props.comment}
              commentID={this.props.parentID || id}
              commenter={user}
              text={content}
              type={this.reactionType}
              eventStatusPost={false}
              tagged={tagged}
              mergeNewPost={() => null}
              time={howLongAgo(time)}
              posterID={this.props.posterID}
              poster={this.props.poster}
              posterText={this.props.posterText}
              parentTagged={this.props.parentTagged}
              posterTime={this.props.posterTime}
              refreshReactions={this.props.refreshReactions}
              eventID={this.props?.event?.id}
              groupID={this.props?.group?.id || this.props?.groupID}
              challengeId={
                this.props?.challenge?.id ||
                this.props?.challengeID ||
                this.props?.challengeId
              }
              activity_sub_type={
                this.props?.event?.category || this.props.activitySubType
              }
              event_record_type={
                this.props.eventRecordType ||
                this.props?.event?.is_virtual !== null
                  ? this.props?.event?.is_virtual
                    ? 'virtual'
                    : 'event'
                  : null
              }
              group_record_type={
                this.props.groupRecordType ||
                this.props.group_record_type ||
                this.props.group?.type
              }
              feed_origin_name={this.props.feed_origin_name}
              feed_origin_type={this.props.feed_origin_type}
              graphData={graphData}
              postGraphData={this.props.postGraphData}
              wholePostObj={this.props.wholePostObj}
              parentID={this.props.parentID} // comment id for replies
            />
          </div>
        </div>
        <div className={styles.commentText}>
          <FormattedPostText
            text={content}
            tagged={tagged}
            linkableUsers={false}
            history={history}
            clickable={true}
            links={links}
          />
          {links.length && graphData?.url ? (
            <SitePreviewCard
              key={links[links.length - 1]}
              link={links[links.length - 1]}
              graphData={graphData}
            />
          ) : null}
          {this.props.comment?.media && <CreatedPostImageDisplay 
            postImages={this.state.media}
            likeAmount={this.state.likeCount}
            liked={this.state.liked}
            handleLikePressed={this.toggleLike}
            parentType={this.reactionType}
          />}
          <div className={styles.likeContainer} onClick={this.handleLikePost}>
            <div
              className={styles.likeIconWrapper}
              onClick={() => this.toggleLike()}>
              <LikeIcon
                className={styles.likeIcon}
                tintColor={this.state.liked ? 'var(--magenta)' : null}
              />
            </div>
            {this.state.likeCount && this.state.likeCount > 0 ? (
              <p
                className={`namesAndObjects`} style={{marginRight: 5}}
                onClick={() => {
                  this.props.history.push({
                    pathname: `/feed/${this.props.postID}/comment/${this.props.comment.id}/like`,
                    state: {feed: this.props.comment},
                  });
                  let analyticsObj = this.baseAnalyticsObj();
                  analyticsObj.action_category = 'comment';
                  // user is the commenter, poster is who created the post
                  analyticsObj.profile_id = `${this.props.comment.user?.id}`;
                  logViewLikeList(analyticsObj);
                }}>
                {this.state.likeCount}{' '}
                {this.state.likeCount > 1 ? 'Likes' : 'Like'}
              </p>
            ) : null}
            <p className='namesAndObjects'
              onClick={() => {this.setState({replyData: {taggedUser: this.props.comment.user}, replyModalVisible: true})}}
            >
              Reply
            </p>
          </div>
        </div>
        {this.state.replies?.map((reply, ind) => {
          const filteredPhotos = filterPostMedia(reply?.data?.media, 'image');
          Object.assign(reply, reply.data); // get content, tagged, etc in the data field
          reply.time = reply.created_at;
          reply.media = filteredPhotos;
          return (
            <div key={reply.id} style={{marginLeft: 30}}>
              <CommentView
                key={ind}
                history={this.props.history}
                refreshReactions={this.props.refreshReactions}
                handleCommentDeletion={this.props.handleCommentDeletion}
                comment={reply} reactionType={REACTION_TYPES.REPLY}
                parentID={this.props.comment.id}
                postID={this.props.postID} posterID={this.props.posterID}
                posterText={this.props.comment?.data?.content}
                poster={this.props.comment.user}
                onDelete={this.handleReplyDeleted}
                parentTagged={this.props.comment?.data?.tagged}
                wholePostObj={this.props.wholePostObj}
              />
            </div>
          )
        })}
        {this.state.replyModalVisible && <CreateComment
          poster={this.props.poster}
          streamID={this.props.postID}
          commentID={this.props.parentID || this.props.comment.id}
          reaction={this.props.comment}
          commenter={user}
          closeModalHandler={() => {
            this.setState({replyModalVisible: false, replyData: {}});
            this.props.refreshReactions();
          }}
          time={howLongAgo(time)}
          text={content}
          tagged={tagged}
          reactionType={REACTION_TYPES.REPLY}
          replyData={this.state.replyData}
          history={history}
          offset={this.props.offset}
          newReaction={true}
          parentTagged={tagged} // parent tag is what is being replied to on create, otherwise it is the parent comment on edit
          wholePostObj={this.props.wholePostObj}
        />}
      </div>
    ) : null;
  }
}

export default withRouter(CommentView);
